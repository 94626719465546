const get = (obj: Record<string, any> = {}, path: string | string[]): any => {
  const properties = Array.isArray(path) ? path : path.split('.');
  return properties.reduce((prev: any, curr: any) => prev && prev[curr], obj);
};

const downloadSessions = async () => {
  const token = localStorage.getItem('token');
  if (!token) return;
  const headers = new Headers({
    Authorization: token,
  });

  const response = await fetch(`${process.env.REACT_APP_API_URL}/sessions/export`, {
    method: 'GET',
    headers,
  });

  if (response.status !== 200) {
    console.log(response);
    return;
  }

  const contentDisposition = response.headers.get('Content-Disposition');
  let fileName = 'sessions.xlsx';
  if (contentDisposition) {
    fileName = (contentDisposition as any).split(';').find((n: any) => n.includes('filename='))
      .replace('filename=', '')
      .trim();
  }

  const blob = await response.blob();
  const urlBlob = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = urlBlob;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  a.remove();
};

export {
  get,
  downloadSessions,
};
