import React from 'react';
import { useNavigate } from 'react-router-dom';
import { downloadSessions } from '~/utils';
import styles from './home.module.scss';
import {
  useUserProfile,
} from '~/hooks/auth';
import {
  useGetUsers,
  useUpdateUser,
} from '~/hooks/users';
import { IUser } from '~/types/users';

const Home = () => {
  const navigate = useNavigate();
  const { data: currentUser } = useUserProfile();
  const { data: users } = useGetUsers();
  console.log(users);

  const {
    mutate: updateUser,
  } = useUpdateUser();

  const toggleRevoke = (user: IUser) => {
    updateUser({
      _id: user._id,
      revoke: !user.revoke,
    });
  };

  return (
    <div className={styles.home}>
      <div className={styles.content}>
        <button onClick={() => downloadSessions()}>Télécharger les données</button>
        <button onClick={() => navigate('add-user')}>Créer un compte</button>
        {(currentUser && (users || []).length > 0) && (
          <>
            <h2>Comptes</h2>
            <ul>
              {(users || [])
                .filter((d: IUser) => d._id !== currentUser._id)
                .map((d: IUser) => (
                <li key={`user-${d._id}`}>
                  {d.profile.firstName} {d.profile.lastName} | {d.email}
                  &nbsp;-&nbsp;
                  <span className={styles.revoke} onClick={() => toggleRevoke(d)}>
                    {!d.revoke
                      ? 'Désactiver l\'accès'
                      : 'Activer l\'accès'
                    }
                  </span>
                </li>
                ))}
            </ul>
          </>
        )}
      </div>
    </div>
  );
};

export default Home;
