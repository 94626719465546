import React, { useEffect } from 'react';
import {
  useNavigate,
  Routes, Route,
} from 'react-router-dom';
import Layout from './components/Layout';
import SignIn from './pages/signin';
import ForgotPassword from './pages/forgot-password';
import ResetPassword from './pages/reset-password';
import { useUserProfile } from './hooks/auth';
import Home from './pages/home';
import AddUser from './pages/add-user';

const Redirect = () => {
  const navigate = useNavigate();
  const { data: user } = useUserProfile();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!user && !token) {
      navigate('/login');
    }
  }, []);

  return (
    <div style={{
      position: 'fixed',
      height: '100%',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
    >
      <p>Loading...</p>
    </div>
  );
};

const AppRoutes = () => {
  const { data: user } = useUserProfile();

  return (
    <Routes>
      <Route path="/login" element={<SignIn />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password/:token" element={<ResetPassword />} />
      {user && (
        <Route path='*' element={
          <Layout key='app'>
            <Routes>
              <Route path='/add-user' element={<AddUser />} />
              <Route path='*' element={<Home />} />
            </Routes>
          </Layout>
        }/>
      )}
      <Route path='*' element={<Redirect />} />
    </Routes>
  );
};

export default AppRoutes;
