/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { IUser } from '~/types/users';

const getUsers = async () => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/users`,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return data?.users;
};

const createUser = async (data: {
  email: string,
  password: string,
  lastName: string,
  firstName: string,
}): Promise<{
  user: IUser,
  token: string
}> => {
  const token = localStorage.getItem('token');
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/users`, {
    email: data.email,
    password: data.password,
    profile: {
      firstName: data.firstName,
      lastName: data.lastName,
    },
  }, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  });

  return response.data;
};

const updateUser = async (data: Partial<IUser>): Promise<{ user: IUser }> => {
  const token = localStorage.getItem('token');
  const { _id, ...updatedData } = data;
  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/users/${_id}`,
    updatedData,
    {
      headers: {
        Authorization: token,
      },
    },
  );

  return response.data;
};

export {
  getUsers,
  createUser,
  updateUser,
};
