import React from 'react';
import { LuLogOut } from 'react-icons/lu';
import { useSignOut } from '../../hooks/auth';
import styles from './navbar.module.scss';

const Navbar = () => {
  const signOut = useSignOut();
  return (
    <div className={styles.navbar}>
      <div className={styles.profile}>
        <button className='invisible' onClick={signOut}>
          <LuLogOut size={26} />
        </button>
      </div>
    </div>
  );
};

export default Navbar;
