import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { FieldValues } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useCreateUser } from '~/hooks/users';
import getErrorAuthMessage from '~/utils/auth-helpers';
import RegisterForm from '~/components/form/RegisterForm';

import styles from './register.module.scss';

export interface CredentialsRequest {
  email: string
  password: string
  firstName: string
  lastName: string
}

const AddUser = () => {
  const navigate = useNavigate();
  const {
    mutate: createUser, isSuccess, error,
  } = useCreateUser();
  const errorApi: any = error;

  const handleSubmit = async (userInfo: FieldValues) => {
    try {
      console.log(userInfo);
      await createUser(userInfo as CredentialsRequest);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      toast('Le compte a été créé');
      navigate('/');
    }
  }, [isSuccess]);

  const errorMessage = useMemo(() => {
    if (!errorApi) return '';
    const msg = getErrorAuthMessage(errorApi?.response?.data?.error || errorApi?.response?.data);
    return msg;
  }, [errorApi]);

  return (
    <div className={styles.register}>
      <div className={styles.container}>
        <div className={styles.containerForm}>
          <div className={styles.form}>
            <h2>Créer un compte</h2>
            <RegisterForm
              submit={handleSubmit}
              errorMessage={errorMessage}
              textSubmit='Valider'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddUser;
