/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import {
  useQuery,
  useQueryClient,
  useMutation,
} from '@tanstack/react-query';
import {
  getUsers,
  createUser,
  updateUser,
} from '~/api/users';
import {
  IUser,
} from '~/types/users';

const useGetUsers = () => useQuery({
  queryKey: ['users'],
  queryFn: async (): Promise<IUser[] | null> => getUsers(),
  keepPreviousData: true,
});

const useCreateUser = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: {
      email: string,
      password: string,
      lastName: string,
      firstName: string,
    }) => createUser(data),
    mutationKey: ['register'],
    onSuccess: async () => {
      queryClient.invalidateQueries(['users']);
    },
  });
};

const useUpdateUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Partial<IUser>) => updateUser(data),
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
    },
  });
};

export {
  useGetUsers,
  useCreateUser,
  useUpdateUser,
};
